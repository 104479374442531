.page-title{
    font-family: nsjp-bold;
    font-size: 38px;
    color: black;
}

.section-title{
    font-size: 20px;
    font-family: nsjp-light;

}

.newsletter-title{
    font-family: lora-regular;
    font-size: 48px;
    line-height: 58px;
    margin-bottom: 32px;
    @include smartphone(){
        @apply text-20 leading-30;
    }
}

.logo-title{
    @apply text-dark-blue text-16
}

.show-title{
    @apply font-nsjp font-bold text-primary-white text-30

}
.show-title-sm{
    @apply font-nsjp font-light text-primary-white text-16   

}