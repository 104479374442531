@import './Mixins/responsive.scss';
@import './Mixins/utils.scss';

@layer components {
    /* GENERAL */
    /*#__next{
        overflow:hidden;
    }*/
    body{
        @apply h-full;

        
    }
    ::-webkit-scrollbar-thumb{
        background : #2699FB;
        border-radius:5px;

    }
    ::-webkit-scrollbar{
        width : 10px;
        background-color:#eaeaea;
    }
    .padded-container {
        @apply px-40
    };

    .flex-row-centered-items {
        @apply flex flex-row items-center
    };

    // index.js
    .container-article {
        @apply border-b-4 border-light-gray pb-16 mb-32;
    };
 
    .container-article:hover {
        @apply cursor-pointer;
    };

    .little-a-container:hover {
        @apply cursor-pointer;
        .little-a-title {
            @apply text-blue-ephemeral-channel
        };
    };

    .container-long-article:hover {
        @apply cursor-pointer;
        .article-long-title {
            @apply text-blue-ephemeral-channel
        };
    };

    .ba-home-container :hover {
        @apply cursor-pointer;
        .article-long-title {
            @apply text-blue-ephemeral-channel
        };
    };

    
    
    

    .default-font-title-content-article {
       @apply font-nsjp font-normal text-primary-dark
    };

    .content-big-article {
        @apply default-font-title-content-article text-16 leading-22 pb-16 h-80 w-full
    };

    .image-article {
        @apply w-full mb-16
    };


    /**********
    BreadCrumb.js
    ***********/

    .breadC-container {
        @apply py-8 font-nsjp font-normal text-12;
        p,a,span{@apply inline;}
    };

    @media screen and (max-width: 1247px)  {
        .breadC-container {
           @apply px-12
        }
    };

    /**********
    TopicHeader.js
    ***********/

    .topicHeader-container {
        @apply pt-8 mb-16 pb-24 px-16 border-b-1 border-gray
    };

    .topicHeader-title {
        @apply font-nsjp font-bold text-38
    };

    .topicHeader-content {
        @apply font-nsjp font-normal text-16
    };

    @media screen and (min-width: 320px) and (max-width: 480px) { 
        .topicHeader-container {
            @apply mb-8 pb-12 px-10 pt-0
        };
        .topicHeader-title {
            @apply text-28
        };
        .topicHeader-content {
            @apply text-14
        }
    };
    /**********
    ShowsAndNews.js
    ***********/

    .sandn-subContainer {
        @apply border-1 border-gray mb-16 pb-8 bg-primary-white transition ease-in-out duration-100 relative;
       

        .timebar{
            @apply absolute bg-gray-2 w-8 left-27 h-full;
        }
    }

    .sandn-bordered-container {
        @apply border-b-1 border-gray
    };

    .sandn-container-title {
        @apply p-8 bg-dark-blue border-gray border-b-1 flex flex-row items-center relative;
    };
    .sandn-ico-programmes {
        @apply text-primary-white text-26 mr-8
    };

    .sandn-content {
        @apply font-nsjp font-medium text-14 text-primary-white self-center
    };

    .sandn-item {
        @apply font-nsjp font-normal text-14
    };

    .sandn-item-shows {
        @apply sandn-item bg-primary-white items-center px-16 relative pt-6 pb-6;
        x
        &:last-of-type{
            margin-bottom: 20px!important;
        }
    };

    .sandn-item-hour{
        @apply flex text-blue-title-sandn w-10per font-nsjp font-bold h-full;
        p {
            @apply z-10 text-center break-normal text-12 h-full bg-primary-white my-32 w-full py-14
        };
    };

    .sandn-item-shows .sandn-item-text{
        @apply text-primary-dark w-85per ml-6
    };



    .sandn-item-news {
        @apply sandn-item mx-16 min-h-80 flex items-center bg-primary-white pl-16 border-gray text-primary-dark;
       
    };
    
    .sandn-item-news .dot {
        @apply mr-1 bg-red-500 h-100per
    };

    .sandn-item-news .dot p{
        @apply w-1 h-1 rounded-full bg-primary-blue
    };

    


    /* Emissions */

    .scrollBarShow::-webkit-scrollbar {
        @apply 
    };

    .scrollBarShow::-webkit-scrollbar-thumb {
        @apply bg-dark-blue rounded-sm
    };

    .search-bar-emission {
        @apply flex flex-row px-16 min-w-288 h-40 rounded-full self-center border-1 border-light-gray bg-light-gray bg-opacity-50
    };

    .search-bar-emission input {
        @apply py-8 outline-none text-12 bg-transparent
    };

    .search-bar-emission {
        input::placeholder, input::-ms-input-placeholder, input::-moz-placeholder {
        @apply text-primary-white;
        }
    };

    // Cities
    
    

    .cities-item {
        @apply transition duration-500 ease-in-out;
        &:hover {
        @apply transform bg-dark-blue;
        a {
            @apply text-primary-white
        }
    }   
    };

    // Around me

    .aroundme-scrollView::-webkit-scrollbar {
        @apply bg-transparent w-0
    };
    
    /**********
    Hard file, TheTeam / Gettings Us / About Us
    ***********/
    
    .hardfile-title {
        @apply font-nsjp font-bold text-18 text-primary-dark my-16
    };
    .hardfile-text {
        @apply text-16 text-primary-dark mb-16
    };

    @include smallTablet() {
        .hardfile-title {
            @apply text-20
        };
        .hardfile-text {
            @apply text-18
        }
    };
    /**********
    contentTemplate.js
    ***********/
    
    .ct-container {
        @apply flex flex-row
    };

    .ct-article-container {
        @apply w-66per mr-16 rounded;
    };

    .ct-right-container {
        @apply w-33per
    };


    @media screen and (min-width: 320px) and (max-width: 1023px) {  
        .ct-article-container {
            @apply w-full mr-0
        };

        .ct-right-container {
            @apply hidden
        }


    }
};

/*****
Components imports 
*****/
/* 
Utils imports 
 */ 

@import "./Utils/button.scss";
@import "./Utils/title.scss";
@import "./Utils/breadcrumb.scss";
/* 
AboutUs imports 
 */ 

@import "./Components/AboutUs/aboutUs.scss";
@import "./Components/Articles/Article.scss";
@import "./Components/Articles/BigArticle.scss";
@import "./Components/Articles/BigArticleHome.scss";
@import "./Components/Articles/ButtonArticle.scss";
@import "./Components/Articles/DateAndTopic.scss";
@import "./Components/Articles/EmptyStack.scss";
@import "./Components/Articles/FirstStackArticles.scss";
@import "./Components/Articles/LittleArticle.scss";
@import "./Components/Articles/LongArticle.scss";
@import "./Components/AuboisNews/AuboisNews.scss";
@import "./Components/Container/index.scss";
@import "./Components/Emissions/emissions.scss";
@import "./Components/GettingUs/gettingUs.scss";
@import "./Components/EphemeralChannels/";
@import "./Components/Imprint";
@import "./Components/MostViewed/";
@import "./Components/Newsletter/newsletter.scss";
@import "./Components/TheTeam/theTeam.scss";
@import "./Components/Dialog/dialog.scss";
@import "./Components/Contact/contact.scss";

/* 
Header & Footerimports 
 */ 

@import "./Header/header.scss";
@import "./Components/Footer/footer.scss";

@import "./Pages/direct.scss";
@import "./Pages/sitemap.scss";
@import "./Pages/aroundme.scss";

