@layer components {
  /**********
    ButtonArticle.js
    ***********/

    .default-container-button-article {
      @apply absolute flex justify-center w-40 h-40 rounded-full bg-blue-ephemeral-channel bg-opacity-70 transition-all duration-100
  };
  .default-container-button-article-base {
    @apply bottom-16 left-16
};
.default-container-button-article-little {
    @apply bottom-8 left-8 w-30 h-30
};
  .default-container-button-article-big {
      @apply bottom-0 right-0 top-0 left-0 m-auto
  };

  .play-button-article {
      @apply text-primary-white self-center
  };
  @include smallTablet() {
    .default-container-button-article {
        @apply w-35 h-35
    };
    .default-container-button-article {
        @apply bottom-0 top-0 m-auto left-0 right-0
    }
  };
}