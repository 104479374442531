@mixin multilineElipsis($line,$lineHeight){
    display: block;
    display: -webkit-box;
    height: $line*$lineHeight;
    margin: 0 auto;
    line-height: $lineHeight;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}