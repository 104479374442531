html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    width: 100%;
}
html{
  overflow-x: hidden;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* Ephemeral channels */
.content-ec-channel {
  text-overflow: ellipsis;
  overflow: auto;
}

.mega-menu {
  display: block;
  left: 0;
  position: absolute;
  text-align: left;
  width: 100%;
}


/* #toggle Class Styles
–––––––––––––––––––––––––––––––––––––––––––––––––– */

/*.toggleable > label:after {
  content: "\25BC";
  font-size: 10px;
  padding-left: 6px;
  position: relative;
  top: 0;
}

.toggle-input {
  display: none;
}

.toggle-input:not(checked) ~ .mega-menu, toggle-input:not(checked) ~ .test  {
  display: none;
}

.toggle-input:checked ~ .mega-menu, toggle-input:checked ~ .test {
  display: block;
}

.toggle-input:checked ~ label:after {
  content: "\25B2";
  font-size: 10px;
  padding-left: 6px;
  position: relative;
  top: 0;
}*/

/* #Carousel show
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.dropdown:hover .dropdown-menu {
  display: block;
}

/* #Contact
–––––––––––––––––––––––––––––––––––––––––––––––––– */



/* #toggle Class Styles
–––––––––––––––––––––––––––––––––––––––––––––––––– */

/*.toggleable-info .test:after, .toggleable-question .test:after {
  content: "\25B2";
  font-size: 10px;
  padding-left: 6px;
  position: relative;
}

.toggle-info, .toggle-question {
  display: none;
}

.toggle-info:not(checked) ~ .menu-info, .toggle-question:not(checked) ~ .menu-question  {
  display: none;
}

.toggle-info:checked ~ .menu-info, .toggle-question:checked ~ .menu-question {
  display: block;
}

.toggle-info:checked .test:after, .toggle-question:checked .test:after {
  content: "\25BC";
  font-size: 10px;
  padding-left: 6px;
  position: relative;
  top: 0;
}
*/
