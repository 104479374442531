@layer components {
  .an-container {
    @apply bg-primary-white border-1 border-gray
  };

  .an-header {
    @apply bg-primary-orange flex flex-row p-12;
    .an-icon {
      @apply mr-8
    };
    .an-header-title {
      @apply font-nsjp font-medium text-14 text-primary-white self-center
    }
  };

  .an-content-carousel {
    @apply bg-primary-white pb-10 relative;
    .control-dots{
      span{cursor:pointer}
    }
    .carousel-slider{
      padding-bottom: 40px;
    }
  };

  .an-carousel-elem {
    @apply p-12 bg-primary-white h-auto cursor-pointer;

    &:hover{
      .aubois-info-title{
        @apply text-blue-ephemeral-channel;
      }
    }
  };
  .an-img-elem-carousel {
    @apply image-article object-contain h-400;
  }
  .an-footer {
      @apply flex flex-wrap justify-center;
      h1 {
        @apply font-nsjp font-normal text-12 text-primary-dark pt-24 pb-16;
      };
      button {
        @apply mb-32;
        p {
          @apply transition ease-in-out duration-300 px-24 py-12 rounded border-1  border-primary-orange font-nsjp font-normal text-14  text-primary-orange 
        };
        p:hover {
          @apply transform text-primary-white bg-primary-orange
        }
      }
  }
}