@layer components {
  .mb-container {
    @apply justify-center flex p-16 bg-primary-white;
    @include tablet() {
      @apply p-0  flex-wrap;
      .mb-left-part,
      .mb-right-part {
        @apply w-full  flex-wrap;
      }
    }
  }
  .mb-sub-container {
    @apply flex flex-row justify-between items-center w-default flex-wrap;
  }
  .mb-left-part {
    @apply flex flex-row w-30per;
    img {
      @apply w-64 h-64 mr-16;
      cursor: pointer;
    }
    .mb-left-text-container {
      @apply self-center font-nsjp font-bold;
      h1 {
        @apply text-dark-blue text-16;
      }
      h3 {
        @apply text-primary-grey text-12;
      }
      @include tablet() {
        .logo-title {
          @apply text-primary-white text-14 leading-none;
        }
        h3 {
          @apply text-primary-white text-12;
        }
      }
    }

    @include tablet {
      @apply bg-dark-blue p-16;
      img {
        @apply w-30 h-30;
      }
    }
  }
  .mb-right-part {
    @apply flex flex-row items-center justify-between w-70per;
    @include tablet() {
      @apply p-16;
    }
  }
  .mb-search-bar-header-respo {
    @apply hidden;
  }
  .mb-search-bar-header {
    @apply relative flex flex-row px-16 w-30per h-40 rounded-full self-center border-1 border-light-gray bg-light-gray opacity-50;

    form {
      @apply self-center w-full;
      input {
        @apply py-8 outline-none text-12 bg-transparent w-full;
      }
    }
    .mb-search-icon {
      @apply text-primary-grey mr-16 self-center;
    }
    @include tablet("ipadPro") {
      @apply w-44per;
    }
    @include tablet() {
      @apply w-full;
    }
  }
  .mb-btn-right-part {
    @apply transition-all ease-in-out duration-200 border-blue-ephemeral-channel border-1 rounded-full px-16 py-10 flex justify-center cursor-pointer items-center;
    .icon-header {
      @apply self-center text-primary-blue mr-8;
    }
    p {
      @apply font-nsjp font-bold text-12 text-center text-blue-ephemeral-channel self-center;
    }

    &:hover {
      @apply transform bg-blue-ephemeral-channel;
      p,
      .icon-header {
        color: white !important;
      }
      .mb-btn-right-icon {
        @apply transform text-primary-white;
      }
    }

    @include tablet() {
      padding: 5px 15px;
      width: 49%;
    }
  }

  .mb-btn-right-icon {
    @apply text-blue-ephemeral-channel text-24 mr-8;
  }
  .mb-btn-container {
    gap: 1rem !important;
    @apply flex flex-row self-center items-center gap-2;
    @include tablet() {
      @apply w-full pt-5 justify-between;
    }
  }

  .mb-responsive-menu {
    @apply hidden;
    &--toggle {
      @apply hidden flex-grow justify-end;
      button {
        @apply border-1 border-solid text-primary-white rounded-lg p-4;

        &:focus {
          @apply text-dark-blue bg-primary-white;
          outline: none;
        }
      }
      @include tablet() {
        @apply flex;
      }
    }
  }
  .mb-btn-right-part-aroundme {
    @apply mr-8;
  }

  @include tablet("ipadPro") {
    .mb-sub-container {
      @apply w-full;
    }
    .mb-right-part {
      @apply flex flex-row items-center;
    }
  }
  @include tablet() {
    .mb-btn-container {
      @apply w-full flex-row justify-items-center justify-between;
    }
    .mb-btn-right-part {
      @apply w-full p-8;
    }
    .mb-btn-right-part-aroundme {
      @apply mr-0;
    }
  }

  .menu-button-consulter {
    @apply transition-all ease-in-out duration-200 text-white px-16 py-10 flex justify-center cursor-pointer items-center;
    p {
      @apply font-nsjp font-bold text-14 text-center text-white self-center;
    }
    &::before {
      content: "";
      height: 3px;
      @apply bg-primary-orange block w-0 transition-all duration-100;
      bottom: 0px;
      position: absolute;
    }
    &:hover {
      @apply bg-blue-ephemeral-channel;
      p {
        @apply font-nsjp font-bold text-14 text-center text-white self-center;
      }
      animation-duration: 1.5s;
    }
  }

  .mb-btn-annonces-container {
    border: 1px solid rgba(2, 59, 109, var(--bg-opacity));
    background-color: rgba(2, 59, 109, var(--bg-opacity));
    color: white;
    padding: 5px 10px;
    @apply flex items-center gap-2;
    @include tablet() {
      @apply hidden;
    }
  }
  .mb-btn-annonces-title {
    text-align: right;
    @apply font-nsjp font-bold text-12 text-white border-r-1 border-solid border-light-gray pr-10 uppercase;
    @include bigTablet() {
        @apply hidden;
    }
  }
}
