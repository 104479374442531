.bc{
    &__container{
        @apply w-full py-10;
        @include bigTablet(){
            @apply px-20;
        }
    }
    &__item{
        @apply text-gray inline-block;

        &.active{
            @apply text-primary-blue pointer-events-none;
            &::after{
                content:'';
            }
        }

        &::after{
            content : '/';
            @apply mx-6 inline-block;
        }
        &:hover{
            text-decoration: underline;
        }
    }
}