    /* FOOTER */
    @layer components {
        .footer-container {
            @apply bg-dark-blue text-white justify-center flex;
        };

        .footer-container-subcontainer {
            @apply w-default flex flex-row justify-between;
            @include tablet(){
                @apply w-full justify-center flex-wrap px-32;
            }
        };

        .footer-left-part {
            @apply w-66per pt-112 mr-16;
            @include tablet(){
                @apply w-full mr-0 text-center pt-60;
            }
        };

        .footer-left-part > h1 {
            @apply font-lora-regular text-48 leading-58 mb-32
        };

        .footer-left-part > h3{
            @apply font-nsjp font-normal text-16 text-primary-grey w-full mb-8
        };

        .footer-left-part-input {
            @apply text-primary-dark px-16 py-12 w-60per rounded-sm
        };

        .footer-vertical-list > h1 {
            @apply font-nsjp font-bold text-16 mb-8
        };

        .footer-vertical-list > a {
            @apply font-nsjp font-normal text-16 mb-16;
            &:hover{
                text-decoration:underline;
            }
        };
        .footer-rubrique {
            @apply font-nsjp font-normal text-16 mb-16
        };
        .footer-annoncer-part {
            @apply mb-80
        };  
        .footer-annoncer-part > h1 {
            @apply font-nsjp font-bold text-12 mb-8
        };
        .footer-annoncer-part > button a {
            @apply font-nsjp font-bold text-16 self-center
        };
        .footer-annoncer-part > button {
            @apply w-75per bg-dark-blue rounded border-2 border-primary-white py-10 flex justify-center transition-all duration-200 ease-in-out
        };
        .footer-annoncer-part > button:focus {
            @apply transform shadow-inner
        };
        .footer-annoncer-part > button:hover {
            @apply text-dark-blue bg-primary-white
        };
        .footer-form {
            @apply w-80per;
        };
        .footer-right-part {
            @apply w-34per mt-48
        };
        .footer-c32-list {
            @apply w-50per mr-32 flex flex-col
        };
        .footer-rubriques-list {
            @apply w-50per flex flex-col;
        };
        .footer-right-part-container {
            @apply flex flex-row mb-40;
            @include tablet(){
                @apply flex-wrap
            }
            @include smartphone(){
                @apply justify-around

            }
        };
        .footer-social-media {
            @apply flex flex-row items-center;
            @include tablet(){
                @apply justify-center
            }
        };

        @media screen and (min-width: 320px) and (max-width: 1024px) { 
        

            .footer-form, .footer-right-part {
                @apply w-full
            };
            .footer-annoncer-part {
                @apply mb-40 items-center flex flex-col;
                h1 {
                    @apply text-14
                }
            }; 
        }
        @media screen and (min-width: 600px) and (max-width: 1024px) {
            .footer-c32-list {
                @apply mr-0 flex mb-32 flex-wrap;
                * {
                    @apply text-center py-4
                }
            };
            .footer-rubriques-list {
                @apply flex-wrap;
                * {
                    @apply text-center py-4
                }
                
            };
        
            .footer-vertical-list {
                > p, h1 {
                @apply mb-0
                }
            };
        };
        @media screen and (min-width: 320px) and (max-width: 480px) {
            .footer-left-part > h1 {
                @apply text-32 leading-normal
            };
            .footer-vertical-list {
                @apply w-48per;
                > * {
                    @apply text-center
                }                                           
            };
            .footer-social-media {
                @apply justify-center
            };

            .footer-c32-list {
                @apply mr-0
            };
            .footer-form {
                @apply w-full;
                input {
                    @apply w-full mb-16;
                    
                };
                input::-webkit-input-placeholder, input:-moz-placeholder, input::-moz-placeholder, input:-ms-input-placeholder {
                    text-align: center;
                }
                
            };
        }
                    
    }
