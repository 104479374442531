.validation-dialog{
    .MuiDialog-paper{
        @apply px-100 pt-40 pb-40;
        @include tablet(){
            @apply px-20;
        }
    }
    .MuiBackdrop-root{
            background-color: rgba(2, 59, 109,0.6);
    }
    .MuiDialogActions-root{
        @apply justify-center;
    }
}