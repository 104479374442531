@font-face {
  font-family: 'icomoon';
  src:  url('../public/fonts/icomoon.eot?vul8p9');
  src:  url('../public/fonts/icomoon.eot?vul8p9#iefix') format('embedded-opentype'),
    url('../public/fonts/icomoon.ttf?vul8p9') format('truetype'),
    url('../public/fonts/icomoon.woff?vul8p9') format('woff'),
    url('../public/fonts/icomoon.svg?vul8p9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ico-actualite:before {
  content: "\e924";
}
.icon-ico-around-orange .path1:before {
  content: "\e923";
  color: ;
}
.icon-ico-around-orange .path2:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ico-around1 .path1:before {
  content: "\e922";
  color: ;
}
.icon-ico-around1 .path2:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ico-direct-small:before {
  content: "\e921";
}
.icon-ico-information:before {
  content: "\e906";
}
.icon-ico-news:before {
  content: "\e907";
}
.icon-ico-programmes:before {
  content: "\e908";
}
.icon-ico-question:before {
  content: "\e909";
}
.icon-ico-acces:before {
  content: "\e920";
}
.icon-ico-coeur-label:before {
  content: "\e91e";
}
.icon-ico-play:before {
  content: "\e91d";
}
.icon-ico-back:before {
  content: "\e91c";
}
.icon-ico-settings:before {
  content: "\e91b";
}
.icon-ico-validate:before {
  content: "\e91a";
}
.icon-ico-share:before {
  content: "\e919";
}
.icon-ico-around:before {
  content: "\e918";
}
.icon-ico-contact:before {
  content: "\e917";
}
.icon-ico-heart-full:before {
  content: "\e916";
}
.icon-ico-heart:before {
  content: "\e915";
}
.icon-ico-big-rafter-right:before {
  content: "\e914";
}
.icon-ico-aubes:before {
  content: "\e900";
}
.icon-ico-send:before {
  content: "\e901";
}
.icon-ico-shows:before {
  content: "\e902";
}
.icon-ico-home:before {
  content: "\e903";
}
.icon-ico-headings:before {
  content: "\e904";
}
.icon-ico-your-news:before {
  content: "\e905";
}
.icon-icon-culture:before {
  content: "\e90b";
}
.icon-icon-le-jt:before {
  content: "\e913";
}
.icon-direct:before {
  content: "\e90a";
}
.icon-icon-sports:before {
  content: "\e90c";
}
.icon-icon-economie:before {
  content: "\e90d";
}
.icon-icon-all:before {
  content: "\e90e";
}
.icon-icon-societe:before {
  content: "\e90f";
}
.icon-icon-loisirs:before {
  content: "\e910";
}
.icon-ico-facebook:before {
  content: "\ea90";
}
.icon-ico-twitter:before {
  content: "\ea96";
}
.icon-ico-rss:before {
  content: "\ea9b";
}
.icon-office:before {
  content: "\e911";
}
.icon-icon-actu:before {
  content: "\e912";
}
.icon-book:before {
  content: "\e91f";
}
.icon-coin-euro:before {
  content: "\e93c";
}
