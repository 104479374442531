.menu-info,.menu-question {
    text-align: left;
    width: 100%;
    overflow: hidden;
    animation-fill-mode: forwards;
    animation: closeInfoForm 1s ease-in-out;
    display: none;


  
    &.open{
      animation: openInfoForm 1s ease-in-out;
      animation-fill-mode: forwards;
      display: block;
    }
  }
  


  @keyframes openInfoForm{
      from{
          max-height: 0;
          display: none;
      }
      1%{
          display: block;
      }
      to{
          max-height: 720px;
      }
  }
@include smartphone(){
    @keyframes openInfoForm{
        from{
            max-height: 0;
            display: none;
        }
        1%{
            display: block;
        }
        to{
            max-height: 900px;
        }
    }
}
  @keyframes closeInfoForm{
      from{
          max-height: 720px;
          display: block;
      }

      99%{
        max-height: 0;

      }
      to{
          display: none;
      }
  }