
@layer components {
    .theTeam-partContainer {
        @apply flex flex-row w-full flex-wrap
    };
    .theTeam-title {
        @apply font-nsjp font-bold text-14 text-primary-dark mb-16 pb-16 border-b-1 border-gray w-75per
    };
    .theTeam-card-container {
        @apply w-31per mx-8 h-full object-cover transition duration-200 ease-in-out mb-16;
        img {
            @apply w-full h-215 object-cover;
            @include smartphone() {
                @apply h-400
            };
            @include myMedia(320, 320) {
                @apply h-200
            }
        };
       
    };
   .theTeam-card-container:hover {
       @apply transform scale-105 shadow-xl
   };
    .theTeam-card-container-content {
        @apply p-16 bg-aboutUs-image;
        .name,.email{
            @apply font-nsjp font-normal
        }
        .role,.email{
            @apply text-14
        }
        .role{
            @apply font-nsjp font-bold text-dark-blue

        }
    };
    @include smallTablet() {
        .theTeam-card-container {
            @apply w-47per
        }
    };
    @include smartphone() {
        .theTeam-card-container {
            @apply w-90per
        };
        .theTeam-partContainer {
            @apply justify-center
        }
    }

}