@layer components {
    .app-button{
        &-primary{
            @apply bg-primary-blue py-2 px-20 rounded text-primary-white font-bold border-2 border-primary-blue cursor-pointer;
            &:focus{
                outline:none;
                @apply shadow-primarybutton;
            }
            &:hover{
                @apply bg-dark-blue border-dark-blue;
            }
        }

        &-outlined{
            @apply bg-transparent border-primary-blue text-primary-blue py-2 px-20 rounded font-bold border-2 transition-all duration-200;
            &:hover{
                @apply bg-primary-blue text-primary-white;
            }
            &:focus{
                outline:none;
                @apply shadow-primarybutton;
            }
            &--danger{
                @apply border-primary-red text-primary-red;
                &:hover{
                    @apply bg-primary-red;
                };
            }
        }

        &-dropdown{
            &:after{
                display : inline-block;
                content:'\25BC';
                margin-left: 10px;
                font-size: 10px;
                vertical-align:2px;
            }
            &--open{
                &:after{
                    display : inline-block;
                    content:'\25B2';
                    margin-left: 10px;
                    font-size: 10px;
                    vertical-align:2px;
                }
            }
        }
    }

    button{
        &.disabled{
            @apply bg-primary-grey pointer-events-none;
            &:hover,&:focus{
                @apply bg-primary-grey pointer-events-none; 
            }
        }
    }
}