@layer components {

    .bn-text {
        @apply font-nsjp font-bold text-12 text-primary-white
     };
     .bn-icon {
     @apply mr-4  
     };
     .bn-container {
         @apply flex p-4 justify-center bn-text bg-dark-blue; 
         @include tablet(){
             @apply hidden;
         }
     };
     .bn-sub-container {
         @apply w-default justify-between flex;
     };
     .bn-left-part {
         @apply flex-row-centered-items justify-between;
     }
     .bn-temperature-container {
         @apply flex-row-centered-items mr-16;
         img {
             @apply mr-4 w-20 h-20;
         }
      };
     .bn-location {
         @apply flex-row-centered-items mr-16;
     };
     .locat-desactivated {
         @apply mr-4 text-center;
     };
     .bn-date {
         @apply flex-row-centered-items;
         p {
             @apply capitalize;
         }
     };
     @media screen and (min-width: 320px) and (max-width: 480px) {
         .locat-desactivated {
             @apply w-40per
         };
         .bn-left-part {
             @apply justify-around
         };
     };
}