    /**********
    LittleArticle.js
    ***********/

    .little-a-container {
      @apply container-article w-49per flex flex-col;
      &:hover{
        .little-a-image{
            transform:scale(1.01) translateZ(0);


        }
    }
  };

  .little-a-title {
      @apply default-font-title-content-article text-20 pb-16
  };

  .little-a-sub-container {
      @apply relative z-0 mb-16;
  };

  .little-a-image {
      @apply w-full z-0;
      transition:all .2s;
      -webkit-backface-visibility: hidden; 
    -ms-transform: translateZ(0); /* IE 9 */
    -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
    transform: translateZ(0);
  };

  .little-a-container-content {
      @apply flex flex-col justify-between h-125 flex-1;
      @include smallTablet() {
          @apply max-h-162
      };
      @include smartphone() {
          @apply h-auto
      }
  };
  
  @include smartphone() {
    .little-a-container {
        @apply mb-20 pb-12 w-full
    };
    .little-a-title {
        @apply text-18 pb-12
    };
    .little-a-image {
        @apply mb-8
    };
  }