 
@layer components {
  /**********
  BigArticle.js
  ***********/

    .big-a-container{
      @apply border-b-4 border-light-gray pb-16 mb-32 cursor-pointer relative;
      &:hover{
        .big-a-image{
            transform:scale(1.01);
            
        }
        .big-a-pagetitle{
            @apply text-primary-blue;
        }

      }
  };

  .big-a-sub-container {
      @apply relative z-0 overflow-hidden;
  };

  .big-a-image {
    @apply w-full z-0;
    transition:all .2s;
    transform:scale(1);
  };

  /*.big-a-fade {
      @apply absolute bottom-0 w-full h-150 bg-gradient-to-t from-dark-blue via-dark-blue-opacity-start to-dark-blue-opacity;
      @include tablet('ipadPro') {
          @apply h-60
      };
      @include smallTablet() {
        @apply h-50
    }

    };*/

  .big-a-bottom-block {
        @apply absolute w-full bottom-0;
        background: rgb(2,59,109);
        background: linear-gradient(0deg, rgba(2,59,109,1) 0%, rgba(2,59,109,1) 70%, rgba(1,24,44,0) 100%);
      /*&::after{
        @apply absolute top-0 w-full h-50 bg-gradient-to-t from-dark-blue via-dark-blue-opacity-start to-dark-blue-opacity block;
        content:'';
        top:-50px;

      }*/
  };

  .big-a-sub-bottom-block {
      @apply relative  p-16;
      
  };

  .big-a-parent-name {
      @apply font-nsjp font-bold text-20 text-primary-orange w-full
  };

  .big-a-pagetitle {
        @apply w-full default-font-title-content-article text-30 pb-8 text-primary-white;
        transition:all .2s;

  };

  @include tablet() {
    .big-a-container{
        @apply mb-12;
    };
    .big-a-parent-name {
        @apply text-18
    };
    .big-a-pagetitle {
        @apply text-22
    }
  };
  @include smartphone() {
    .big-a-parent-name {
        @apply text-14
    };
    .big-a-pagetitle {
        @apply text-18
    }
   
    .big-a-fade {
        @apply h-75
    };
    .big-a-sub-bottom-block {
        @apply p-8
    };
  };
}