@layer components {
    .aboutUs-part-container {
        @apply mb-32
    };
    .aboutUs-image {
        @apply w-49per object-contain mb-8;
        @include smartphone() {
            @apply w-full mb-16
        }
    };
    .aboutUs-container-image {
        @apply flex flex-row justify-around items-center flex-wrap
    };
    @include smartphone() {
        .aboutUs-title {
            @apply text-18
        };
        .aboutUs-content, .aboutUs-content-bold {
        }
    }
}