@mixin smallDesktop(){
    @media screen and (max-width:1600px){
      @content; 
    }
    
}

@mixin myMedia($minWidth, $maxWidth) {
  @if $minWidth and $maxWidth {
    @media screen and (min-width: ($minWidth + 'px')) and (max-width:($maxWidth + 'px')){
      @content; 
    }
  } @else {
    @if $maxWidth {
      @media screen and (max-width:($maxWidth + 'px')){
        @content; 
      }
  }
  @if $minWidth  {
    @media screen and (min-width:($minWidth + 'px')){
      @content; 
    }
}
}
}
@mixin bigTablet($option:'isTabletOnly'){
    @if$option=="yes" {
      @media screen and (min-width:1024px) and (max-width:1247px){
        @content; 
      }
    } @else {
      @media screen and (max-width:1247px){
        @content; 
      }
    }    
}


@mixin tablet($option:'tablet'){
 
        @if$option=="ipadPro" {
            @media screen and (max-width:1024px){
                @content; 
            }
        } @else{

        @media screen and (max-width:1023px){
            @content; 
        }
     }
  
  
}


@mixin smallTablet(){
  @media screen and (max-width:600px){
    @content; 
  }
  
}

@mixin smartphone(){
    @media screen and (max-width:480px){
        @content; 
    }        
}



