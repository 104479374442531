@layer components {
      /**********
    LongArticle.js
    ***********/

    .article-long-container, .article-long-little-container {
      @apply flex pt-20 pb-32 border-b-4 border-light-gray cursor-pointer;
      &:hover{
        .article-long-title{
            @apply text-primary-blue;
        }
      }
  };

  
  .article-long-little-container {
      @apply pb-16 pt-16
  };

    .article-long-container-image, .article-long-little-container-image{
      @apply relative max-h-full z-0 mr-16 flex items-center
  };

  .article-long-container-image {
    @apply w-33per
  };

  .article-long-little-container-image {
      @apply w-22per
  };

  .article-long-content-container {
      @apply w-66per flex flex-wrap
  };

  .article-long-image {
      @apply z-0
  };

  .article-long-title {
      @apply default-font-title-content-article text-20 w-full
  };

  @media screen and (min-width: 600px) and (max-width: 1023px) { 
      .article-long-container{
          @apply py-16
      };
      .article-long-title {
          @apply text-20
      }
  };

  @media screen and (min-width: 414px) and (max-width: 480px) { 
      .article-long-container-image {
          @apply w-40per
      };
  
      .article-long-content-container {
          @apply w-60per
      };
  }
  @media screen and (min-width: 320px) and (max-width: 480px) { 
      .article-long-title {
          @apply text-14
      }
      .article-long-container{
          @apply py-12
      };
  };

  @media screen and (min-width: 320px) and (max-width: 413px) { 
      .article-long-title {
          @apply text-12
      }
  };

  @media screen and (min-width: 320px) and (max-width: 480px) {
      .empty-a-container {
          @apply h-220;
          h1 {
              @apply text-22;
          }
          p {
              @apply text-16
          }
          
      };
  }
}