.ba-home-container  {
    @apply flex flex-col pb-16 mb-32 border-b-4 border-light-gray w-full;
    &:hover {
        .ba-home-image {
            transform:scale(1.01);         
        };

        .ba-home-title{
            @apply text-primary-blue;
        }
    }
};

.ba-home-container-image {
    @apply relative z-0 overflow-hidden;
};

.ba-home-image {
    @apply w-full mb-8;
    transition:all .2s;
    transform:scale(1);        
    
}
.ba-home-container-content {
    @apply w-full flex flex-wrap
};
.ba-home-title {
    @apply mb-16
}