.emissions{
    &__theme{
        padding-bottom:30px !important;
        max-width:100%;
        

        .swiper-button-next,.swiper-button-prev{
            background-color: rgba(1,24,44,0.8);
            width: 50px;
            height:50px;
            border-radius:50%;
            border: 2px solid white;
            top: 35%;

            &::after{
                font-size: 20px;
                color: white;
            }
        }
        .swiper-button-next{
            @include smartphone(){
                right: 0;
            }
        }
        .swiper-button-prev{
            @include smartphone(){
                left: 0;
            }
        }

        .swiper-button-disabled{
            display: none;
        }

        .swiper-scrollbar-drag{
            background-color: #2699FB;
        }
        .swiper-scrollbar{
            @apply left-0 w-full;
        }
        .left-mask{
            background: linear-gradient(90deg, rgba(1,24,44,1) 0%, rgba(255,255,255,0) 100%);
            position: absolute;
            left: 0;
            top: 0;
            width: 80px;
            z-index: 5;
        }
        .right-mask{
            background: linear-gradient(90deg, rgba(1,24,44,0) 0%, rgba(1,24,44,1) 100%);
            position: absolute;
            right: 0;
            top: 0;
            width: 80px;
            z-index: 5;
        }
        &.swiper-start{
            .left-mask{
                display: none;
            }
        }
        &.swiper-end{
            .right-mask{
                display: none;
            }
        }

        &__item{
            @apply px-0;

            .emission-description{
                @apply absolute w-full  p-20 text-xs font-nsjp font-normal hidden;
                top: 0; left: 0;
                background-color: rgba(0,24,44,0.8);


                &__text{
                    @apply overflow-hidden;
                    @include multilineElipsis(4,20px)
                }
            }

            &:hover{
                .emission-description{
                    @apply flex;
                    animation : fadeIn .2s ease-in-out;
                } 
                .emission-title{
                    @apply text-primary-blue transition-all duration-200;
                }
            }   
                   
        }


    }
}
.show-container{
    

    .show-section{
        @apply relative z-10  py-100 flex bg-primary-dark bg-opacity-70;
        box-shadow: inset 0px 0px 65px 67px rgba(1,24,44,1);

        flex-wrap: wrap;

        .show-description{
            max-width: 230px;
            @include tablet{
                max-width: none;
            }
        }
    }

    .show-background{
        @apply absolute inset-0 z-0 bg-center bg-no-repeat bg-cover;
        filter:blur(15px);
    }
}

.show-episode{
    &:hover{
        .show-title-sm{
            @apply text-primary-blue;
        }
        .default-container-button-article{
            @apply w-45 h-45;
        }
    }
}
@keyframes fadeIn{
    from{
        opacity: 0;
    }
    to{
        opacity:1;
    }
}

.shows-carousel{
    .control-dots{
        p{cursor: pointer;}
    }

    &__item{
        &-title{
            @apply font-nsjp font-bold text-white text-left mb-8;
        }
        &:hover{
            .shows-carousel__item-title{
                @apply text-primary-blue;
            }
        }

    }
}