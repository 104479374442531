.ephem-ch-container {
  @apply transition duration-100 ease-in-out mb-16 rounded overflow-hidden py-16 mt-16 bg-blue-ephemeral-channel flex flex-row flex-wrap text-primary-white ;
  

  .ephem-ch-list{
    max-height:310px;
    margin-right: 7px;
    padding-right: 7px;
    &::-webkit-scrollbar{
      @apply bg-transparent w-4;
    }
    &::-webkit-scrollbar-thumb{
      background: white;
      border-radius: 5px;
    }
  }
};

.ephem-ch-container > h3 {
  @apply pt-8 pb-8 font-nsjp font-light text-20 text-center self-center text-primary-white mb-5 w-full px-16;
};

.ephem-ch-channel{
  @apply flex-row font-nsjp font-bold text-16 w-full flex justify-center items-center text-blue-ephemeral-channel cursor-pointer transition-all duration-200 px-16 py-10;
  &:hover {
      background-color: #046ac3;
  };
  img {
    @apply w-116 h-66 object-cover mr-6;
  };
  p {
     @apply align-middle inline-block self-center text-center bg-primary-white  w-full rounded-sm ;
 };
};