.around-me{
    .around-me-map{
        width:100%;
        transition: all .3s ease-in-out;
    }
    .around-me-cities{
        transform: translateX(100%);
        width: 50%;
        transition: all .3s ease-in-out;

    }
    &.open{
        .around-me-map{
            width: 50%;
            @include tablet(){
                width: 20%;
            }
        }
        .around-me-cities{
            transform:translateX(0);
            @include tablet(){
                width: 80%;
            }
        }
    }
}