.nb-container {
    @apply flex items-center justify-center flex-wrap bg-primary-white; 
    @include tablet(){
        @apply fixed z-1000 transform translate-x-full transition-all duration-500 items-start ;
        top:0;
        left: 20%;
        right: 0;
        bottom: 0;

        &.open{
            @apply transform translate-x-0 overflow-auto;
            
        }
    }
};
.nb-sub-container{
    @apply flex flex-row w-default relative;
    @include tablet(){
        @apply flex-wrap w-65per py-50 ;

    }
    .close-menu{
        @apply hidden;
        top: 20px;
        right: -30px;
        @include tablet(){
            @apply block;
        }
    }
   
}
.overlay{
    @apply hidden transition-all duration-500 opacity-0;
    @include tablet(){
        @apply fixed bg-black bg-opacity-75 z-999 inset-0;    
        
        &.open{
            animation : showMenuOverlay .5s;
            @apply block opacity-100;
        }
    }

    
}
.nb-rubriques-container {
    @apply text-16 font-nsjp font-medium flex items-center text-primary-dark w-66per;
    @include tablet(){
        @apply flex-wrap w-full justify-center;
    }
}

.nb-rubrique-topics-container {
    @apply flex flex-row justify-between w-full;
    @include tablet() {
        @apply flex-col
    }
}

.nb-menu-item-active{
    @apply text-blue-ephemeral-channel;
};

.nb-menu-home {
    @apply w-120 flex flex-row items-center justify-start;
    @include bigTablet(){
        @apply ml-8 w-150
    };
    @include tablet() {
        @apply justify-center
    }

};

.icon-navbar-home {
    @apply text-20 mr-8;
    @include tablet {
        @apply mr-0
    };
    @include tablet() {
        @apply mr-16 text-30
    }
};

.nb-separator {
    @apply w-1 bg-primary-grey h-20;
    @include tablet() {
        @apply hidden
    };
};

.nb-right-part {
    @apply text-16 font-nsjp font-medium flex justify-between items-center w-34per; 
    @include bigTablet(){
       @apply w-40per;
    }
    @include tablet(){
        @apply flex-wrap w-full;
       
        .menu-item,.toggleable{
            @apply w-full text-center text-28;
        }
        .toggleable{
            label{
               &::after{@apply hidden} 
            }       
        }
        .see-direct{
            @apply h-auto py-16 bg-primary-white text-primary-red justify-center text-28;
            svg{
                @apply hidden;
            }
        }
        .info-ville-menu{
            @apply fixed m-0 inset-0;
            a{
                @apply w-full m-0;
            }
        }
    };
    @include smartphone(){
        .menu-item,.toggleable,.see-direct{
            @apply text-22; 
        }
    }
    .nb-text-info-ville {
        @apply text-primary-orange block cursor-pointer py-16 relative;
        &::before{
            content:'';
            height:3px;
            @apply bg-primary-orange block w-0 transition-all duration-100;
            bottom:0px;
            position: absolute;
        }
        &:hover{
            &::before{
                @apply w-full;
            }
        }
    }
    
}
.nb-menu-item {
    @apply font-nsjp font-medium py-16 text-center relative text-dark-blue;
    &:hover{
        &::after{
            @apply w-full;
        }
    }
    @include tablet(){
        @apply w-full text-28;
    }
    @include smartphone(){
        @apply text-22;
    }
    &::after{
        content:'';
        height:3px;
        @apply bg-blue-ephemeral-channel block w-0 transition-all duration-100;
        bottom:0px;
        position: absolute;
    }
    &:focus,&:active{
   
        @apply text-blue-ephemeral-channel;
        &::after{
            @apply w-full;
        }

    }

    &-active{
        @apply text-blue-ephemeral-channel;    
        &::after{
            @apply w-full;
        }
    }
};

.mv-container{
    .menu-villes{
    
        @apply absolute left-0 w-full justify-center h-screen z-999 hidden;
        overflow: hidden;
       
        &__overlay{
            @apply bg-black bg-opacity-25 inset-0 relative h-full w-full z-10;
            opacity:0;
        }
        &__content{
            width: 100%;
            position: absolute; 
            top: 0; 
            padding:0px 400px 20px 400px;
            max-height:0;
            overflow: hidden;
            will-change: max-height;
            @include smallDesktop(){
                padding:0px 250px 20px 250px;
    
            }
            @include bigTablet(){
                padding:0px 200px 20px 200px;
            }
            @include tablet{
                min-height: auto;
                height: 100%;
                width: 100%;
            }
        }

    }
    &.open{
        .menu-villes{
            display: flex;
            &__overlay{
                animation: showMenuOverlay .5s ease-in-out;
                opacity: 1;
            }
            &__content{
                animation : showMenu .5s ease-in-out;
                max-height: 500px;
            }

                        
        }
        &.is-closing{
            .menu-villes__overlay{
                animation: closeMenuOverlay .5s ease-in-out;
                opacity: 0;
            }
            .menu-villes__content{
                animation : closeMenu .5s ease-in-out;
                max-height: 0px;
            }

        }


    }
    @include tablet(){
        @apply fixed;
        top: 0;left: 20%;right: 0;bottom: 0;
        transform: translateX(100%);
        transition: all .5s;
        z-index: 1001;

        &.open{
            transform:translateX(0);
            .menu-villes{
                @apply relative w-full h-full ;
    
                &__content{
                    @apply h-full w-full h-full px-20;
                    max-height: none;
                    animation: none;
                    a{
                        @apply w-full;
                        @include smartphone(){
                            @apply mb-3;
                        }
                    }
    
    
                }
            }
            &.is-closing{
                .menu-villes__content{
                    animation : closeMenuSmartphone .5s ease-in-out;
                    max-height: none;

                }
            }
        }
       

        
        
    }


}


@keyframes showMenu{
    from{
        max-height: 0px;
    }
    to{
        max-height:500px;
    }
}
@keyframes closeMenu{
    from{
        max-height:500px;
    }
    to{
        max-height:0px;
    }
}
@keyframes closeMenuSmartphone{
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(100%);

    }
}
@keyframes showMenuOverlay{
    from{
        opacity:0;
    }
    to{
        opacity:1;
    }
}
@keyframes closeMenuOverlay{
    from{
        opacity:1;
    }
    to{
        opacity:0;
    }
}

.nb-city-item {
    @apply flex flex-row items-center w-30per mr-16 mb-24 justify-between py-8 border-b-1 border-gray;
    svg{
        @apply transition-all duration-200 ease-in-out mr-0;
    }
    &:hover {
        svg{
            @apply -mr-1
        }
    }
}
.nb-live-icon-animation {
    @apply animate-pulse;
    animation-duration: 1.5s;
};

.nb-live-button {
    @apply cursor-pointer flex flex-row bg-primary-red text-primary-white h-full items-center px-20;
    &:hover {
        .nb-live-icon, a {
            @apply animate-pulse;
            animation-duration: 1.5s;
        }
    }
}

.nb-annonces-container {
    @apply hidden flex-col items-center w-full;
    @include tablet(){
        @apply flex;
    }
}
