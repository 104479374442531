/*@font-face {
    font-family: "nsjp-bold";
    src: url("../fonts/Noto_Sans_JP/NotoSansJP-Bold.otf");
};

@font-face {
    font-family: "nsjp-medium";
    src: url("../fonts/Noto_Sans_JP/NotoSansJP-Medium.otf");
};

@font-face {
    font-family: "nsjp-regular";
    src: url("../fonts/Noto_Sans_JP/NotoSansJP-Regular.otf");
};

@font-face {
    font-family: "nsjp-light";
    src: url("../fonts/Noto_Sans_JP/NotoSansJP-Light.otf");
};
*/
@font-face {
    font-family: "lora-regular";
    src: url("../fonts/Lora/static/Lora-Regular.ttf");
};

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

