@layer components {
    .gettingUs-title {
        @apply font-nsjp font-bold text-14 text-primary-dark mb-16 pb-16 border-b-1 border-gray w-75per
    };
    .gettingUs-card-container {
        @apply w-31per h-full object-cover mr-16 transition duration-200 ease-in-out mb-16;
        img {
            @apply w-full h-full;
         
        };
        @include tablet() {
            @apply w-47per
        };
        @include smartphone() {
            @apply w-full
        }

    };
   .gettingUs-card-container:hover,  .gettingUs-reception-container:hover {
       @apply transform scale-105 shadow-xl
   };
    .gettingUs-card-container-content {
        @apply p-16 bg-aboutUs-image;
        h1, h3 {
            @apply font-nsjp font-normal
        };
        h2 {
            @apply font-nsjp font-bold text-dark-blue
        };
        h2, h3 {
            @apply text-14
        }

    };
    .gettingUs-reception-container {
        @apply min-w-66per flex flex-col items-center;
        img {
            @apply w-50per my-32
        };
        .gettingUs-card-container-content {
            @apply flex flex-col items-center w-full
        };
        @include smallTablet() {
            @apply min-w-full
        }
    };
    .gettingUs-container-reception {
        @apply flex flex-row;
        @include smallTablet() {
            @apply flex-wrap
        }
    };
    .getttingUs-button-label {
        @apply self-center text-primary-white text-14 font-nsjp font-bold bg-blue-ephemeral-channel p-16 rounded-md
    };
    .getttingUs-button-container {
        @apply self-center
    };
    .gettingUs-showZone-container {
        @apply min-h-500 w-full transition-all duration-500 transform h-auto block
    };

}