.brn-container{
    @include tablet(){
        display: none;
    }
    height: 30px;
    @apply relative;
    pointer-events: none;


    .brn-info{
        line-height: 30px;
    }

    .carousel__slide--focused{
        outline: none;
        border: none;
    }
}