.nl-container{
    &__topline{
        @apply text-black text-xs;
    }

    &__form{
        @apply py-30;
        
        .nl-input{
            padding-right : 30px;
            width:50%;
            margin-bottom:20px;
            @include smartphone(){
                @apply w-full pr-0;
            }
        }

        .nl-interest{
            @apply relative bg-light-gray py-2 px-10 cursor-pointer transition-all duration-100 ease-in-out text-sm font-bold rounded flex items-center;
            color:#a2a2a2;
            &:hover{
                background-color:#2e699c;
                color: #fff;
                svg{
                    path{
                        fill:white;
                    }
                }
            }
            &.selected{
                @apply bg-dark-blue text-primary-white;
                .check-icon{
                    @apply block;   
                }
                svg{
                    path{
                        fill:white;
                    }
                }
            }
            .check-icon{
                @apply absolute text-primary-white hidden;
                right: 5px;
            }
            svg{
                margin-right: 10px;
                path{
                    fill: #a2a2a2;
                }
            }
           
            
        }
        .MuiInputLabel-shrink{
            font-weight: bold;
        }
    }
}