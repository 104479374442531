/**********
Article.js

***********/
@layer components {
  .article-cast-start{
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    background-color: rgba(100,100,100,0.8);

    img, .play-cast{
      height: 70px;
      position: relative;
      margin: 0 auto;
      top: 40%;
      display: block;
    }

    p{
      position: relative;
      margin: 0 auto;
      top: 40%;
      color: white;
      text-align: center;
    }
  }

  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

    .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .article-cast-player{
    position: relative;
  }

  .article-alone-container {
    @apply container-article border-b-0 mb-0;
    h1 {
        @apply font-nsjp font-normal text-30 text-black mb-28 mt-16
    }
  };

  .article-alone-media-container {
    @apply relative mb-24 z-0;
    .carousel-root{
      .control-arrow{
        @apply bg-dark-blue bg-opacity-60 opacity-100;
        &:hover{
          @apply bg-dark-blue bg-opacity-80;
        }
        &:before{
          border-top: 16px solid transparent;
          border-bottom: 16px solid transparent;
        }
        &.control-next{
          &:before{
            border-left: 16px solid white;
          }
        }
        &.control-prev{
          &:before{
            border-right: 16px solid white;
          }
        }
      }
    }

  };

  .article-alone-image {
    @apply w-full z-0
  };

  .article-alone-media-controls {
    @apply relative z-10 cursor-pointer;
    .default-container-button-article-big{
      @apply cursor-pointer z-20;

    }
    video{
      @apply w-full;
    }
    &:hover{
      .default-container-button-article-big{
        @apply bg-opacity-100;
      }      
      .cast-icon{
        @apply block;
      }
    }
  };

  .article-alone-media-controls-container{
    @apply flex flex-row justify-end items-center bg-black bg-opacity-25 font-nsjp font-bold text-12 text-primary-white absolute bottom-0 right-0 left-0;
    
    .artAlo-currentTime,svg,.article-alone-controls-fullscreen-part{
      cursor:pointer;
    }
  };

  .article-alone-controls-time-part {
    @apply w-70per items-center flex flex-row;
    .artAlo-currentTime {
        @apply w-90per mx-4
    };
  };

  .article-alone-controls-fullscreen-part {
    @apply w-15per
  };

  .article-alone-content {
    @apply default-font-title-content-article text-16 leading-22 w-full pb-80 border-solid border-b-2 border-light-gray mb-20; 
    a{
      @apply  transition-all duration-200 text-primary-blue;
      &:hover{
        @apply underline text-dark-blue;
      }
    }
    
 
  };
  @include tablet() {
    .article-alone-container {
      @apply mb-0
    }
  };
  @include tablet('ipadPro') {
    .article-alone-content {
      @apply text-18 leading-normal
    }
  };
  @include smartphone() {
    .article-alone-container {
      h1 {
          @apply text-24 mb-16 mt-0
      }
  };
  .article-alone-controls-time-part {
      @apply w-60per; 
  }
  } 
}