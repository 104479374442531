@layer components {
    /**********
    EmptyArticle.js
    ***********/

    .empty-a-container {
        @apply w-full h-440 flex flex-col items-center justify-center;
        h1 {
            @apply font-nsjp font-bold text-24 text-dark-blue text-center mb-16;
        }
        p {
            @apply text-18 font-nsjp font-bold text-gray-button-navbar
        }
        
    };
}