@layer components {
    /**********
    DateAndTopic.js
    ***********/

    .dandt-container {
        @apply flex items-end
    };

    .dandt-container-alone {
        @apply px-16 py-8 bg-light-gray;
        position: relative;

        google-cast-launcher {
            float: right;
            position: absolute;
            right: 10px;
            top: 3px;
            width: 35px;
            border: none;
            outline: none;
            --disconnected-color: black;
            --connected-color: black;
          }
      
        google-cast-launcher:hover {
            --disconnected-color: black;
            --connected-color: black;
        }
    };

    .dandt-date-topic {
        @apply text-12 font-nsjp font-normal;
    };

    .dandt-text-date {
        @apply dandt-date-topic text-primary-grey
    };

    .dandt-text-date-big-article{
        @apply dandt-date-topic text-primary-white
    };

    .text-topic {
      @apply dandt-date-topic text-primary-blue
    };

    .text-topic-big-article {
        @apply dandt-date-topic text-primary-white
    };

    @include smartphone() {
        .dandt-text-date, .text-topic, .dandt-text-date-big-article{ 
            @apply text-12
        }; 
    }
}