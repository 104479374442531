@layer components {
    .imprint-title {
        @apply font-nsjp font-bold text-20 text-primary-dark mb-16
    };
    .imprint-link {
        @apply text-dark-blue font-nsjp font-bold
    };
    .imprint-link:hover {
        @apply text-primary-blue
    }
}